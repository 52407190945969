<template>
  <ion-card class="mx-0 my-0 py-2" @click="onTapQuotationDetail">
    <div class="d-flex justify-space-between mx-3">
      <div class="d-flex ion-align-items-center">
        <ion-label class="fs-1 ml-1">{{ formatDateAndTime(data?.created_at) }}</ion-label>
      </div>
      <div class="d-flex ion-align-items-center" v-if="!isMerchantUser">
        <div v-if="data.quotation_status.toUpperCase() === QUOTATION_STATUSSES.REQUESTED">
          <ion-button fill="outline">
            <ion-label> {{ $t('takeAction') }} </ion-label>
          </ion-button>
        </div>
        <ion-badge
          v-else
          slot="end"
          :color="
            data?.quotation_status?.toUpperCase() == QUOTATION_STATUSSES.REJECTED ? 'danger' : 'primary'
          "
          class="d-flex ion-align-item-center"
          style="border-radius: 1rem"
        >
          <ion-label class="fs-1 py-1 px-1 ion-text-capitalize fw-500">{{
            data?.quotation_status
          }}</ion-label>
          <ion-icon class="pt-1 pr-1" :icon="renderIconQuotation(data?.quotation_status)"></ion-icon>
        </ion-badge>
      </div>
    </div>
    <ion-item class="ion-align-items-start" lines="none">
      <div class="mr-2" slot="start" style="margin-top: 10px; border-radius: 50%">
        <div v-if="imagePath() === IMAGE_PATH.CUSTOMER && !data.image">
          <div
            class="image-default"
            :style="`background: ${handleRandomBackground().background}; color:${
              handleRandomBackground().color
            }`"
          >
            {{ customerName }}
          </div>
        </div>
        <td-image
          v-else
          style="width: 48px; height: 48px"
          alt="thumbnail-notification"
          :image="data?.image ?? ``"
          :imagePath="imagePath()"
        ></td-image>
      </div>
      <ion-label v-if="!isMerchantUser">
        <ion-text class="fw-500 fs-2">
          {{ `${data?.requestor?.first_name} ${data?.requestor?.last_name}` || '-' }}
        </ion-text>
        <div class="mt-1">
          <ion-note color="medium" class="ion-text-wrap fw-400 fs-2 mt-2">
            <ion-icon :icon="homeOutline"></ion-icon>

            {{ data?.customer_name || '-' }}
          </ion-note>
        </div>
      </ion-label>
      <ion-label v-else class="d-flex w-100 my-auto py-2" style="flex-direction: column">
        <ion-text class="fw-500 fs-2" style="white-space: wrap">
          {{ data?.sku_name || '-' }}
        </ion-text>
        <ion-avatar class="pt-1" style="height: 100%" v-if="data?.halal">
          <ion-img style="width: 24px; height: 24px" src="/assets/images/halal.svg" alt="img" />
        </ion-avatar>
      </ion-label>
    </ion-item>
    <div class="d-flex justify-space-between ion-align-items-center mx-3 mt-2" v-if="!isMerchantUser">
      <div class="d-flex ion-align-items-center w-90">
        <ion-label class="fs-1 ml-1">{{ data?.sku_name }}</ion-label>
      </div>
      <div class="d-flex ion-align-items-center" v-if="data?.price">
        <ion-label class="fs-2 py-1 px-1 fw-600" color="primary">{{ priceLabel }}</ion-label>
      </div>
    </div>
    <div class="d-flex justify-space-between ion-align-items-center mx-3 mt-2" v-else>
      <div class="d-flex ion-align-items-center w-90">
        <ion-label class="fs-1 ml-1">{{ $t('quotation_price') }}:</ion-label>
        <ion-label
          v-if="data?.quotation_status?.toUpperCase() !== QUOTATION_STATUSSES.REJECTED"
          class="fs-2 py-1 px-1 fw-600"
          color="primary"
          >{{ priceLabel }}</ion-label
        >
      </div>
      <div class="d-flex ion-align-items-center">
        <ion-badge
          slot="end"
          :color="
            data?.quotation_status?.toUpperCase() == QUOTATION_STATUSSES.REJECTED ? 'danger' : 'primary'
          "
          class="d-flex ion-align-item-center"
          style="border-radius: 1rem"
        >
          <ion-label class="fs-1 py-1 px-1 ion-text-capitalize fw-500">{{
            data?.quotation_status
          }}</ion-label>
          <ion-icon class="pt-1 pr-1" :icon="renderIconQuotation(data?.quotation_status)"></ion-icon>
        </ion-badge>
      </div>
    </div>
  </ion-card>
</template>
<script>
import { QUOTATION_STATUSSES } from '@/modules/shared/constants';
import { useCardQuotation } from '@/usecases/notifications';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardQuotation',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isMerchantUser: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const {
      imagePath,
      IMAGE_PATH,
      onTapQuotationDetail,
      formatDateAndTime,
      homeOutline,
      priceLabelQuotation,
      renderIconQuotation,
      renderInitialCustomerNameThumbnail,
      priceLabel
    } = useCardQuotation(props);
    const { customerName, handleRandomBackground } = renderInitialCustomerNameThumbnail();

    return {
      formatDateAndTime,
      priceLabel,
      customerName,
      handleRandomBackground,
      homeOutline,
      priceLabelQuotation,
      renderIconQuotation,
      onTapQuotationDetail,
      imagePath,

      IMAGE_PATH,
      QUOTATION_STATUSSES
    };
  }
});
</script>
<style lang="scss" scoped>
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}
.unread-icon {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--ion-color-danger);
}
.out-round {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #ffd7d7;
  position: relative;
}
.in-round {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--ion-color-danger);
  position: absolute;
  top: 19%;
  left: 20%;
}
ion-button {
  margin-left: -1px;
  width: fit-content;
  height: 26px;
  text-transform: capitalize;
  ion-label {
    margin-bottom: 0;
    font-size: 13px;
  }
}
</style>
